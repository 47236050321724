body {
    background: black;
    color: white;
}

.banner {
    background: url('../img/banner.jpg') no-repeat;
    background-position: 50%;
    background-size: cover;
    height: 770px;
    width: 100%;
}

.contain {
    position: relative;
}


/* NAV */

.navbar {
    margin-bottom: 0;
}

.nav.navbar-nav {
    margin-top: 5px;
}

nav {
    z-index: 1000;
}


/* TEXT */

h1,
h2,
h3 {
    font-family: 'Nunito', sans-serif;
    color: white;
    text-transform: uppercase;
}

p,
ol {
    color: white;
}

h2 {
    font-weight: 700;
    font-size: 29px;
}

h2.btn_title {
    padding: 0;
    margin: 0;
}

.bg1 a {
    color: red;
}

a {
    outline: none;
}


/* FOOTER */

footer {
    padding-top: 30px;
}

footer a {
    color: #fff;
}

footer p {
    color: white;
}


/* BACKGROUNDS */

.bg {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    bottom: 0px;
    color: white;
    width: 100%;
    padding: 20px 0;
}

.bg2 {
    background: #16609d;
    color: #111 !important;
    padding: 40px 0;
}

.bg2 p,
.bg2 h1 {
    color: #FFF !important;
}

.bg3 {
    background: #333;
    color: white;
    padding: 50px 0;
}


/* MISC */

button.btn.btn-large {
    display: block;
    margin: 20px auto;
    padding: 10px 25px;
    background: #AED20D;
    ;
    color: #000;
    border: 3px solid #fff;
    width: 80%;
    max-width: 400px;
    font-family: 'Nunito', sans-serif;
    font-size: 30px;
}

.icon {
    width: 30%;
    display: block;
    margin: 0 auto;
}

.border {
    border: 10px solid #322E31;
}

.logo {
    max-width: 300px;
    width: 100%;
}

.form {
    background: rgba(0, 0, 0, 0.8);
    max-width: 400px;
    margin-top: 75px;
}

.pad {
    margin: 100px 0;
}

.imgBdr {
    border: 10px solid #fff;
}


/* MODAL LOGIN */

.modal-content {
    text-align: center;
    max-width: 300px;
    margin: 5em auto;
}

.modal-content label {
    margin-top: 5px;
}

.modal-content input {
    width: 100%;
    text-align: center;
    margin: 0px 0px 15px;
}

.modal-content .modal-footer {
    text-align: center;
}

.modal-content .modal-footer .btn {
    width: 100%;
}

.modal-backdrop {
    z-index: 100;
}

.modal-title {
    color: black;
}

.modal-body label {
    color: black;
}

input {
    color: black;
}


/* MEDIA QUERIES */

@media (max-width: 991px) {
    .bg {
        position: static;
        background: black;
    }
    .one,
    .two,
    .three {
        text-align: center;
    }
    img.circle {
        display: block;
        margin: 0 auto;
    }
    .marBtmMobile {
        margin-bottom: 2em;
    }
}

.logo {
    max-width: 60%;
    padding-top: 5px;
}

@media (max-width: 991px) {
    .navbar-header {
        width: 30%;
    }
    .logo {
        max-width: 100%;
    }
}

.navbar-default {
    border: none;
    border-radius: 0px;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus {
    background: #A40A20;
    color: #fff;
}

.navbar.navbar-default {
    border-bottom: 2px solid #F13451;
}

@media (max-width: 800px) {
    .banner {
        background-position: center;
        max-height: 500px;
        width: 100%;
    }
    button.btn.btn-large {
        font-size: 20px;
    }
}

@media (max-width: 767px) {
    .navbar-header {
        width: 100%;
    }
    .navbar-toggle {
        margin-top: 1em;
    }
    .navbar .navbar-nav>li>a {
        height: 40px;
        display: block;
        text-align: center;
    }
    .nav .nav-divider {
        margin-bottom: 0px;
    }
    .nav.navbar-nav {
        margin-top: 0px;
    }
    .logo {
        max-width: 30%;
        margin-left: 1em;
        margin-top: 0em;
    }
}

@media (max-width: 600px) {
    .col-xs-6 {
        width: 100%;
    }
    .icon {
        margin: 10px auto;
    }
}

@media (max-width: 420px) {
    button.btn.btn-large {
        font-size: 15px;
    }
    .logo {
        max-width: 50%;
        margin-left: 1em;
        margin-top: 0.25em;
    }
}

body.modal-open {
    overflow: auto !important;
    padding-right: 0 !important;
}

.navbar-inverse .navbar-nav>li>a {
    color: #9d9d9d !important;
    outline: none;
}

.navbar-inverse .navbar-nav>li>a:focus,
.navbar-inverse .navbar-nav>li>a:active {
    color: #9d9d9d !important;
}

.navbar-inverse .navbar-nav>li>a:hover {
    color: #fff !important;
}